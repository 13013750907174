import React, { ReactNode, createContext, useState, useEffect, useContext } from 'react'
import { getAuthToken, setAuthToken, removeAuthToken } from 'utils/auth'

import { userInfo } from 'api/auth'

interface AuthContextType {
  user: any
  signIn: (accessToken: string, user: object) => void
  signOut: () => void
}

const AuthContext = createContext<AuthContextType>(null!)

export const AuthProvider = ({ children }: { children: ReactNode }) => {

  let [user, setUser] = useState<any>(null)

  let accessToken = getAuthToken()

  let signIn = async (accessToken: string, user: object) => {
    setAuthToken(accessToken)
    setUser({})
    getUser()
  }  

  let signOut = (): void => {
    removeAuthToken()
    setUser(null)
  }

  let getUser = () => {
    if (accessToken) {
      userInfo().then(result => {
        setUser(result.data.user)
      })
    } else {
      setUser(null)
    }
  }

  let value = { user, signIn, signOut }

  useEffect(() => {
    if (!accessToken || !user) {
      getUser()
    }
  })

  return (
    <AuthContext.Provider value={value}>
      {children}
    </AuthContext.Provider>
  )
}

export const useAuth = () => {
  return useContext(AuthContext)
}
