import React from 'react'

import { boardPostPickList, boardPostInfo, boardPostPick } from 'api/community'

import SearchList from 'components/template/SearchList'

import UiButton from 'components/ui/UiButton'

const ComunityPostPickList = (): JSX.Element => {
  let onPick: Function = (data?: any, afterSuccess?: Function): void => {
    if (window.confirm(data.pick ? '정말 메인에서 내리시겠습니까?' : '정말 메인에 올리시겠습니까?')) {
      boardPostPick({
        _id: data._id,
        state: !data.pick
      }).then(() => {
        if (afterSuccess) {
          afterSuccess()
        }
      }).catch(error => {
        alert(error.message)
      })
    }
  }

  return (
    <div className="community-post-list">
      <SearchList listApi={boardPostPickList}
                  infoApi={boardPostInfo}
                  infoChildren={(data?: any, afterSuccess?: Function) => {
                    if (data.pick) {
                      return (
                        <>
                          <UiButton color="white"
                                    onClick={() => { onPick(data, afterSuccess) }}>
                            <><i className="xi-check-circle"/> 노픽</>
                          </UiButton>
                        </>
                      )
                    } else {
                      return (
                        <>
                          <UiButton color="white"
                                    onClick={() => { onPick(data, afterSuccess) }}>
                            <><i className="xi-check-circle-o"/>Pick</>
                          </UiButton>
                        </>
                      )
                    }
                  }} />
    </div>
  )
}

export default ComunityPostPickList
