import React from 'react'

import { roomRequestList, roomRequestInfo, roomRequestDelete } from 'api/talk'

import SearchList from 'components/template/SearchList'

const TalkRoomRequestList = (): JSX.Element => {
  return (
    <div className="talk-list">
      <SearchList listApi={roomRequestList}
                  infoApi={roomRequestInfo}
                  deleteApi={roomRequestDelete} />
    </div>
  )
}

export default TalkRoomRequestList
