import React from 'react'

import { boardRequestList, boardRequestInfo, boardRequestCheck } from 'api/community'

import SearchList from 'components/template/SearchList'

import UiButton from '../../components/ui/UiButton'

const ComunityRequestList = (): JSX.Element => {
  let onCheck: Function = (data?: any, afterSuccess?: Function): void => {
    if (window.confirm('정말 처리되었습니까?')) {
      boardRequestCheck({
        _id: data._id
      }).then(() => {
        if (afterSuccess) {
          afterSuccess()
        }
      }).catch(error => {
        alert(error.message)
      })
    }
  }

  return (
    <div className="community-request-list">
      <SearchList listApi={boardRequestList}
                  infoApi={boardRequestInfo}
                  infoChildren={(data?: any, afterSuccess?: Function) => {
                    return (
                      <>
                        <UiButton color="white"
                                  onClick={() => { onCheck(data, afterSuccess) }}>
                          <><i className="xi-check-circle-o"/> 처리</>
                        </UiButton>
                      </>
                    )
                  }}/>
    </div>
  )  
}  

export default ComunityRequestList
