import httpClient from 'utils/http'

export function purchaseList (params: Object): Promise<any> {
  const url = '/pc/p/l'

  return httpClient.post(url, params).then(response => {
    return response.data
  })
}

export function purchaseInfo (params: Object): Promise<any> {
  const url = '/pc/p/i'

  return httpClient.post(url, params).then(response => {
    return response.data
  })
}

export function purchaseUpsert (params: Object): Promise<any> {
  const url = '/pc/p/u'

  return httpClient.post(url, params).then(response => {
    return response.data
  })
}

export function purchaseItemList (params: Object): Promise<any> {
  const url = '/pc/i/l'

  return httpClient.post(url, params).then(response => {
    return response.data
  })
}

export function purchaseItemInfo (params: Object): Promise<any> {
  const url = '/pc/i/i'

  return httpClient.post(url, params).then(response => {
    return response.data
  })
}

export function purchaseItemUpsert (params: Object): Promise<any> {
  const url = '/pc/i/u'

  return httpClient.post(url, params).then(response => {
    return response.data
  })
}
