import React from 'react'

import { roomList, roomInfo, roomDelete } from 'api/talk'

import SearchList from 'components/template/SearchList'

const TalkRoomList = (): JSX.Element => {
  return (
    <div className="talk-list">
      <SearchList listApi={roomList}
                  infoApi={roomInfo}
                  deleteApi={roomDelete} />
    </div>
  )  
}  

export default TalkRoomList
