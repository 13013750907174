import React from 'react'

import { translationList, translationInfo, translationUpsert } from 'api/translation'

import SearchList from 'components/template/SearchList'

const TranslationList = (): JSX.Element => {
  return (
    <div className="translation-list">
      <SearchList listApi={translationList}
                  infoApi={translationInfo}
                  editApi={translationUpsert} />
    </div>
  )  
}  

export default TranslationList
