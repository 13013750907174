import React from 'react'

import { boardCommentList, boardCommentInfo, boardCommentDelete } from 'api/community'

import SearchList from 'components/template/SearchList'

const BoardCommentList = (): JSX.Element => {
  return (
    <div className="board-comment-list">
      <SearchList listApi={boardCommentList}
                  infoApi={boardCommentInfo}
                  deleteApi={boardCommentDelete} />
    </div>
  )
}

export default BoardCommentList
