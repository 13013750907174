import React from 'react'

import { placeList, placeInfo, placeUpsert, placeDelete } from 'api/place'

import SearchList from 'components/template/SearchList'

const PlaceList = (): JSX.Element => {
  return (
    <div className="place-list">
      <SearchList listApi={placeList}
                  infoApi={placeInfo}
                  insertApi={placeUpsert}
                  editApi={placeUpsert}
                  deleteApi={placeDelete} />
    </div>
  )  
}  

export default PlaceList
