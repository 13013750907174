import httpClient from 'utils/http'

export function placeList (params: Object): Promise<any> {
  const url = '/p/l'

  return httpClient.post(url, params).then(response => {
    return response.data
  })
}

export function placeInfo (params: Object): Promise<any> {
  const url = '/p/i'

  return httpClient.post(url, params).then(response => {
    return response.data
  })
}

export function placeUpsert (params: Object): Promise<any> {
  const url = '/p/u'

  return httpClient.post(url, params).then(response => {
    return response.data
  })
}

export function placeDelete (params: Object): Promise<any> {
  const url = '/p/d'

  return httpClient.post(url, params).then(response => {
    return response.data
  })
}
