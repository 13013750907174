import Dropzone from 'react-dropzone'
import { Component  } from 'react'
import {base64ToFile, resizeImage} from '../../utils/tool'

import defaultBlankImage from '../../assets/images/asset--no-image.svg'

import './Image.scss'

class Image extends Component {
  state = {
    thumbnail: null,
    error: null,
    newName: null
  }

  onClick = () => {
    if (this.props.onClick) {
      this.props.onClick()
    }
  }

  setThumbnail = (thumbnail) => {
    const {
      blankImage
    } = this.props

    if (thumbnail) {
      this.setState({
        thumbnail
      })
    } else {
      this.setState({
        thumbnail: blankImage
      })
    }
  }

  onDrop = (file, change = true) => {
    const {
      resizeSize,
      imageType,
      imageQuality,
      type
    } = this.props

    if (!file) {
      this.setState({ error: '업로드 중 에러가 발생했습니다. 다시 시도해주세요.' })
      return
    }

    if (typeof file !== 'string') {
      const reader = new FileReader()

      const setError = () => this.setState({ error: '업로드 중 에러가 발생했습니다. 다시 시도해주세요.' })
      reader.onabort = () => setError()
      reader.onerror = () => setError()
      reader.onload = () => {
        if ((file.type && file.type.substr(0, 6) === 'image/')) {
          resizeImage(file, reader.result, {resizeSize, imageType, imageQuality})
            .then(({thumbnailUrl, resizedFile}) => {
              this.setThumbnail(thumbnailUrl)
              this.setState({
                newName: file.name
              })
              if (change) {
                this.imageOnChange(resizedFile)
              }
            }).catch(err => {
              console.error(err)
              setError()
            })
        } else {
          this.setThumbnail(null)
          this.setState({
            newName: file.name
          })
          if (change) {
            this.imageOnChange(file)
          }
        }
      }
      reader.readAsDataURL(file)
    } else {
      if (!type || type === 'i') {
        this.setThumbnail(file)
      } else {
        this.setThumbnail(null)
      }
    }
  }

  imageOnChange = (value) => {
    if (this.props.onChange) {
      this.props.onChange(value)
    }
  }

  componentDidMount () {
    const {
      blankImage,
      value
    } = this.props

    if (value) {
      this.onDrop(value, false)
    } else {
      this.setState({
        thumbnail: blankImage
      })
    }
  }

  render() {
    const {
      thumbnail,
      newName
    } = this.state

    const {
      width,
      height,
      placeholder,
      error,
      description
    } = this.props

    let className = `ui-image`
    let thumbnailStyle = {
      width,
      height,
      backgroundImage: `url(${thumbnail})`
    }

    return (
      <div className={className}>
        <div className="ui-image-dropzone">
          <Dropzone onDrop={acceptedFiles => this.onDrop(acceptedFiles[0])}>
            {({ getRootProps, getInputProps }) => (
              <div { ...getRootProps() }>
                <input { ...getInputProps() } />
                <div className="thumbnail"
                     style={thumbnailStyle}> </div>
                {!!(newName || placeholder) &&
                  <div className="filename">{newName || placeholder}</div>
                }
              </div>
            )}
          </Dropzone>
        </div>

        {!error &&
          <p className="ui-input-description">
            {description}
          </p>
        }
        {!!error &&
          <p className="ui-input-description error">
            {error}
          </p>
        }
      </div>
    )
  }
}

Image.defaultProps = {
  blankImage: defaultBlankImage,
  value: null,
  type: null,
  width: '89px',
  height: '89px',
  resizeSize: { width: null, height: null },
  imageType: 'image/jpeg',
  imageQuality: 0.7,
  placeholder: '',
  onChange: null,
  onClick: null,
  error: null,
  description: null
}

export default Image