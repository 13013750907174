import httpClient from 'utils/http'

export function adminList (params: Object): Promise<any> {
  const url = '/m/l'

  return httpClient.post(url, params).then(response => {
    return response.data  
  })  
}

export function adminInfo (params: Object): Promise<any> {
  const url = '/m/i'

  return httpClient.post(url, params).then(response => {
    return response.data
  })
}

export function adminUpsert (params: Object): Promise<any> {
  const url = '/m/u'

  return httpClient.post(url, params).then(response => {
    return response.data
  })
}

export function adminDelete (params: Object): Promise<any> {
  const url = '/m/d'

  return httpClient.post(url, params).then(response => {
    return response.data
  })
}
