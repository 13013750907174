import React from 'react'

const Dashboard = (): JSX.Element => {
  return (
    <div>
    </div>
  )  
}  

export default Dashboard  
