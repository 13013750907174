import React from 'react'
import { format } from 'date-fns'
import { utcToZonedTime } from 'date-fns-tz'
import { getS3Url } from 'utils/tool'

import UiMap from 'components/ui/UiMap'

import 'react-quill/dist/quill.snow.css'
import './UiData.scss'

const UiData = ({ value, option }: { value: any, option: any }): JSX.Element => {
  const GetResult: Function = (props: any): JSX.Element => {
    let result = props.value
    let tipValue = ''
    let tipClass = ''

    if (option.type[0] === 'date' || option.type[0] === 'datetime') {
      if (value) {
        let tz: number = -parseInt(option.type[1].substr(3, 2))
        let tzString: string = tz.toString()
        if (tz >= 0) {
          tzString = '+' + tzString
        }
        tipValue = option.type[1]
        tipClass = 'timezone'

        if (option.type[0] === 'date') {
          result = format(utcToZonedTime(result, 'Etc/GMT' + tzString), 'yyyy-MM-dd')
        } else if (option.type[0] === 'datetime') {
          result = format(utcToZonedTime(result, 'Etc/GMT' + tzString), 'yyyy-MM-dd HH:mm:ss')
        }
      }
    } else if (option.type[0] === 'timeRange') {
      if (result) {
        const days: { [key: string]: string } = {
          ho: "공",
          mo: "월",
          tu: "화",
          we: "수",
          th: "목",
          fr: "금",
          sa: "토",
          su: "일"
        }

        result = result.map((range: any) => {
          if (range.d && range.s && range.e) {
            return (
              <div>
                {days[range.d] + ' ' + range.s + ' ~ ' + range.e}
              </div>
            )
          }
          return ''
        })
      }

    } else if (option.type[0] === 'number') {
      if (value) {
        result = result.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',')
      }
    } else if (option.type[0] === 'string') {
      if (option.type[1] === 'password') {
        result = '암호화되어 저장됩니다'
      } else if (option.type[1] === 'wysiwyg') {
        result = <div className="wysiwyg inner"><div className="ql-editor" dangerouslySetInnerHTML={{ __html: result }}></div></div>
      } else if (option.type[1] === 'long') {
        result = <div className="wysiwyg inner" dangerouslySetInnerHTML={{ __html: result ? result.replace(/\n/g, '<br>') : '' }}></div>
      }
    } else if (option.type[0] === 'type') {
      if (result === 'group') {
        result = '그룹'
      } else if (result === 'direct') {
        result = '1:1'
      } else if (result === 'post') {
        result = '글'
      } else if (result === 'comment') {
        result = '댓글'
      } else if (result === 'talk') {
        result = '채팅방'
      } else if (result === 'Archive') {
        result = '아카이브'
      } else if (result === 'ArchiveCategory') {
        result = '아카이브 분류'
      } else if (result === 'ArchiveArticle') {
        result = '아카이브 글'
      } else if (result === 'Board') {
        result = '커뮤니티 게시판'
      } else if (result === 'BoardPost') {
        result = '커뮤니티 글'
      } else if (result === 'BoardPostComment') {
        result = '커뮤니티 댓글'
      } else if (result === 'TalkMsg') {
        result = '채팅 메세지'
      } else if (result === 'Banner') {
        result = '배너'
      } else if (result === 'disabledDueToDate') {
        result = '3일 해체'
      } else if (result === 'warnDisabledDueToDate') {
        result = '3일 해체 경고'
      } else if (result === 'leaveUser') {
        result = '퇴장'
      } else if (result === 'newUser') {
        result = '입장'
      } else if (result === 'image') {
        result = '이미지'
      }
    } else if (option.type[0] === 'boolean') {
      result = result ? '예' : '아니오'
    } else if (option.type[0] === 's3') {
      if (result) {
        result = getS3Url(result)
        if (option.type[1] === 'image') {
          result = <img src={result} alt=""/>
        }
      }
    } else if (option.type[0] === 'user') {
      if (result) {
        result = <>
          <span className="data-item">
            <span className="ui-data type-static">
              {result._id}
            </span>
          </span>
          <span className="data-item">
            <span className="data-title">로그인ID</span>
            {result.userId}
          </span>
          <span className="data-item">
            <span className="data-title">이메일</span>
            {result.email}
          </span>
          <span className="data-item">
            <span className="data-title">닉네임</span>
            {result.nickname}
          </span>
          <span className="data-item">
            <span className="data-title">국가</span>
            {result.country}
          </span>
          <span className="data-item">
            <span className="data-title">언어</span>
            {result.lang}
          </span>
          {!!result.leavedAt &&
          <span className="data-item">
            <span className="data-title">퇴장일</span>
            {format(utcToZonedTime(result.leavedAt, 'Etc/GMT+9'), 'yyyy-MM-dd HH:mm:ss')}
            <span className="tip timezone">UTC+9</span>
            </span>
          }
          {!!result.deletedAt &&
            <span className="data-item">
            <span className="data-title">탈퇴일</span>
              {format(utcToZonedTime(result.deletedAt, 'Etc/GMT+9'), 'yyyy-MM-dd HH:mm:ss')}
              <span className="tip timezone">UTC+9</span>
            </span>
          }
        </>
      } else {
        result = null
      }
    } else if (option.type[0] === 'admin') {
      if (result) {
        result = <>
          <span className="data-item">
            <span className="ui-data type-static">
              {result._id}
            </span>
          </span>
          <span className="data-item">
            <span className="data-title">이메일</span>
            {result.userId}
          </span>
          <span className="data-item">
            <span className="data-title">이름</span>
            {result.nickname}
          </span>
          {!!result.deletedAt &&
          <span className="data-item">
            <span className="data-title">삭제일</span>
            {format(utcToZonedTime(result.deletedAt, 'Etc/GMT+9'), 'yyyy-MM-dd HH:mm:ss')}
            <span className="tip timezone">UTC+9</span>
            </span>
          }
        </>
      } else {
        result = null
      }
    } else if (option.type[0] === 'chat') {
      if (result) {
        result = <>
          <span className="data-item">
            <span className="ui-data type-static">
              {result._id}
            </span>
          </span>
          <span className="data-item">
            <span className="data-title">대화일</span>
            {format(utcToZonedTime(result.createdAt, 'Etc/GMT+9'), 'yyyy-MM-dd HH:mm:ss')}
            <span className="tip timezone">UTC+9</span>
          </span>
          {!result.type &&
            <>
              <span className="data-item">
                <span className="data-title">닉네임</span>
                {result.user ? result.user.nickname : ''}
              </span>
              <span className="data-item">
                <span className="data-title">언어</span>
                {result.originalLang}
              </span>
              <span className="data-item">
                <span className="data-title">원어</span>
                {result.message}
              </span>
            </>
          }
          {!!result.translation &&
            <span className="data-item">
              <span className="data-title">ko-KR</span>
              {result.translation}
            </span>
          }
          {result.type === 'system' &&
            <>
              <span className="data-item">
                <span className="data-title">닉네임</span>
                {result.user ? result.user.nickname : ''}
              </span>
              <span className="data-item">
                <span className="data-title">시스템</span>
                {result.message ? result.message.type : ''}
              </span>
            </>
          }
          {result.type === 'image' &&
            <>
              <span className="data-item">
                <span className="data-title">닉네임</span>
                {result.user ? result.user.nickname : ''}
              </span>
              <span className="data-item type-image">
                  <span className="data-title">이미지</span>
                  {(result.message && result.message.image && result.message.image.key) &&
                    <img src={getS3Url(result.message.image.key) || undefined} alt="" />
                  }
                </span>
            </>
          }
        </>
      } else {
        result = null
      }
    } else if (option.type[0] === 'translation') {
      if (result) {
        result = <>
          {!!result._id &&
            <span className="data-item">
              <span className="ui-data type-static">
                {result._id}
              </span>
            </span>
          }
          {!!result.originalLang &&
            <span className="data-item">
                <span className="data-title">언어</span>
              {result.originalLang}
            </span>
          }
          {!!result.title &&
            <span className="data-item">
              <span className="data-title">이름</span>
              {result.title}
            </span>
          }
          {!!result.titleKo &&
            <span className="data-item">
              <span className="data-title">ko-KR</span>
              {result.titleKo}
            </span>
          }
          {!!result.content &&
            <span className="data-item">
              <span className="data-title">내용</span>
              {result.content}
            </span>
          }
          {!!result.contentKo &&
            <span className="data-item">
              <span className="data-title">ko-KR</span>
              {result.contentKo}
            </span>
          }
          {!!result.deletedAt &&
            <span className="data-item">
              <span className="data-title">삭제일</span>
              {format(utcToZonedTime(result.deletedAt, 'Etc/GMT+9'), 'yyyy-MM-dd HH:mm:ss')}
              <span className="tip timezone">UTC+9</span>
            </span>
          }
        </>
      } else {
        result = null
      }
    } else if (option.type[0] === 'file') {
      if (result) {
      result.url = getS3Url(result.key)
        if (result.type === 'i') {
          result = <img src={result.url} alt="" />
        } else {
          result = <a href={result.url} target="_blank">{result.originalFileName}</a>
        }
      }
    } else if (option.type[0] === 'coord') {
      result = result && result.coordinates ? result.coordinates.join(' ') : result
      if (result) {
        const splited = result.split(' ')
        const v = {
          lat: parseFloat(splited[1]),
          lng: parseFloat(splited[0])
        }
        result = <UiMap value={v}
                        disabled={true} />
      }
    }
    if (option.options) {
      for (const opt of option.options) {
        if (opt.value === value)
          result = opt.text
      }
    }
    return (<span className={`ui-data type-${option.type[0]} type-${option.type[1]}`}
          title={value}>
      {result}
      {!!tipValue &&
        <span className={`tip ${tipClass}`}>
          {tipValue}
        </span>
      }
    </span>)
  }

  let result: any = <></>
  if (option.array) {
    if (value) {
      result = value.map((v: any) => {
        return <div className="ui-data-item">
          <GetResult value={v}/>
        </div>
      })
    }
  } else if (option.translation) {
    if (value) {
      result = Object.entries(value).map(([lang, v]) => {
        return <div className="ui-data-item item-lang">
          <span className="ui-data-lang">{lang}</span>
          <GetResult value={v}/>
        </div>
      })
    }
  } else {
    result = <GetResult value={value} />
  }

  return result
}

export default UiData
