import React from 'react'

import { archiveList, archiveInfo, archiveUpsert, archiveDelete } from 'api/archive'

import SearchList from 'components/template/SearchList'

const ArchiveList = (): JSX.Element => {
  return (
    <div className="archive-list">
      <SearchList listApi={archiveList}
                  infoApi={archiveInfo}
                  insertApi={archiveUpsert}
                  editApi={archiveUpsert}
                  deleteApi={archiveDelete} />
    </div>
  )  
}  

export default ArchiveList
