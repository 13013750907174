import React from 'react'

import './UiNavigation.scss'

const UiNavigation = ({ pageNum, pageSize, totalCount, navSize, onGoToPage, onSetItemsPerPage }
                        : { pageNum: number, pageSize: number, totalCount: number, navSize: number, onGoToPage?: Function, onSetItemsPerPage?: Function }): JSX.Element => {

  const navMin: number = 1

  const navMax: number = Math.floor((totalCount - 1) / pageSize) + 1

  const navStart: number = Math.max(pageNum - (navSize - 1 - Math.min(Math.floor(navSize / 2),
      navMax - pageNum)), navMin)
  
  const navEnd: number = Math.min(pageNum + Math.max(Math.floor(navSize / 2),
      navSize - pageNum), navMax)

  const navArray: Array<number> = new Array(Math.max(navMin, navEnd - navStart + 1))
    .fill(navStart).map((n, i) => n + i)

  const goToPage: Function = (page: number): void => {
    if (onGoToPage) {
      onGoToPage(page)
    }
  }

  const setItemsPerPage: Function = (no: number): void => {
    if (onSetItemsPerPage) {
      const offsetCount = (navMax === navMin || pageNum === navMax)
        ? totalCount : pageNum * pageSize
      const num = Math.ceil(offsetCount / no)
      onSetItemsPerPage(num, no)
    }
  }
  
  return (
    <div className="ui-navigation">
      <div className="page-buttons">
        <button className="arrow"
                disabled={pageNum === navMin}
                onClick={() => goToPage(navMin)}>
          <i className="xi-angle-left-min" />
        </button>

        {navArray.map(i => {
          return (
            <button key={`nav${i}`}
                    className={pageNum === i ? 'active' : ''}
                    onClick={() => goToPage(i)}>
              {i}
            </button>
          )
        })}

        <button className="arrow"
                disabled={pageNum === navMax}
                onClick={() => goToPage(navMax)}>
          <i className="xi-angle-right-min" />
        </button>
      </div>
    </div>

//   <div class="page-size">
//     <button v-for="no in [10, 30, 50]"
//     :class="{ active: pageSize === no }"
//     :key="`${no}PerPage`"
//     @click="setItemsPerPage(no)">
//     {{ no }}
//   </button>
//   <span>개씩</span>
//
//   <span v-if="totalCount"
//         class="total-count">
//         모두 <em>{{ totalCount }}</em>개
//       </span>
// </div>
//   <div class="clear"></div>
// </div>

  )
}

export default UiNavigation
