import React, { useState, useEffect } from 'react'
import { hashPassword } from 'utils/auth'
import { getData, objectToForm } from 'utils/tool'
import { useLoader } from 'context/Loader'

import UiButton from 'components/ui/UiButton'
import UiNavigation from 'components/ui/UiNavigation'
import UiData from 'components/ui/UiData'
import UiDataInput from 'components/ui/UiDataInput'

import './SearchList.scss'

const SearchList = ({ listApi, infoApi, insertApi, editApi, deleteApi, infoChildren }
                      : { listApi: Function, infoApi?: Function, insertApi?: Function, editApi?: Function, deleteApi?: Function,
                          infoChildren?: any }): JSX.Element => {
  const [list, setList] = useState([])
  const [count, setCount] = useState(0)
  const [columns, setColumns] = useState([])
  const [option, setOption] = useState({
    count: 25,
    page: 0,
    sortBy: 'createdAt',
    sortOption: -1,
    filter: {}
  })
  const [filter, setFilter] = useState([])

  const [detailId, setDetailId] = useState('')
  const [detailData, setDetailData] = useState({})
  const [detailMeta, setDetailMeta] = useState([])

  const [editMode, setEditMode] = useState(false)
  const [editValue, setEditValue] = useState({})

  const [insertMode, setInsertMode] = useState(false)
  const [insertValue, setInsertValue] = useState({})

  let detailCount = 0
  if (infoChildren) {
    detailCount++
  }
  if (editApi) {
    detailCount++
  }
  if (deleteApi) {
    detailCount++
  }

  let loader = useLoader()

  let loadList: Function = (): void => {
    setDetail(null)
    loader.addLoading()
    listApi(option).then((res: any) => {
      setList(res.data.list)
      setCount(res.data.count)
      setColumns(res.data.column)
      setDetailMeta(res.data.meta)
      setFilter(res.data.filter)
      window.location.href = '#0'
      loader.removeLoading()
    }).catch((error: Error) => {
      alert(error.message)
      loader.removeLoading()
    })
  }

  let setSearch: Function = (field: string, value: any, key?: string): void => {
    type filterType = {
      [index: string]: any
    }

    let f: filterType = {
      ...option.filter
    }
    if (key) {
      if (!f[field]) {
        f[field] = {}
      }
      if (value) {
        f[field][key] = value
      } else {
        delete f[field][key]
      }
    } else {
      f[field] = value
    }

    setOption({
      ...option,
      filter: f
    })
  }

  let search = (): void => {
    loadList()
  }

  let goToPage: Function = (page: number): void => {
    setOption({
      ...option,
      page: page - 1
    })
  }

  let setDetail: Function = (item: any): void => {
    setEditMode(false)
    if (item) {
      if (infoApi) {
        let timeout = 0
        if (detailId) {
          setDetailId('')
          timeout = 500
        }
        setTimeout(() => {
          setDetailId(item._id)
          loader.addLoading()
          infoApi(item).then((res: any) => {
            setDetailData({
              ...res.data.info,
              password: ''
            })
            loader.removeLoading()
            window.location.href = '#1'
          }).catch((error: Error) => {
            alert(error.message)
            loader.removeLoading()
          })
        }, timeout)
      }
    } else {
      setDetailId('')
      window.location.href = '#0'
    }
  }

  let onSetEditMode: Function = (v: boolean): void => {
    setEditMode(v)
    setEditValue({})
    window.location.href = '#1'
  }

  let onSetEditValue: Function = (field: string, value: any): void => {
    setEditValue({
      ...editValue,
      [field as keyof typeof editValue]: value
    })
  }

  let saveEdit = (): void => {
    if (editApi) {
      if (window.confirm('정말로 저장하시겠습니까?')) {
        let params = {
          ...editValue,
          _id: detailId
        }
        if (params.hasOwnProperty('password')) {
          let password = 'password' as keyof typeof params
          params[password] = hashPassword(params[password])
        }
        loader.addLoading()
        editApi(objectToForm(params)).then((res: any) => {
          loadList()
          setDetail(res.data.info)
          loader.removeLoading()
        }).catch((error: Error) => {
          alert(error.message)
          loader.removeLoading()
        })
      }
    }
  }

  let onDelete = (): void => {
    if (deleteApi) {
      if (window.confirm('정말로 삭제하시겠습니까?')) {
        loader.addLoading()
        deleteApi({ _id: detailId }).then((res: any) => {
          loadList()
          loader.removeLoading()
        }).catch((error: Error) => {
          alert(error.message)
          loader.removeLoading()
        })
      }
    }
  }

  let onSetInsertMode: Function = (v: boolean): void => {
    setInsertMode(v)
    setInsertValue({})
    window.location.href = '#2'
  }

  let onSetInsertValue: Function = (field: string, value: any): void => {
    setInsertValue({
      ...insertValue,
      [field as keyof typeof insertValue]: value
    })
  }

  let saveInsert = (): void => {
    if (insertApi) {
      if (window.confirm('정말로 생성하시겠습니까?')) {
        let params = {
          ...insertValue,
          _id: ''
        }
        if (params.hasOwnProperty('password')) {
          let password = 'password' as keyof typeof params
          params[password] = hashPassword(params[password])
        }
        loader.addLoading()
        insertApi(objectToForm(params)).then((res: any) => {
          loadList()
          setDetail(res.data.info)
          onSetInsertMode(false)
          loader.removeLoading()
        }).catch((error: Error) => {
          alert(error.message)
          loader.removeLoading()
        })
      }
    }
  }

  useEffect(() => {
    loadList()
  }, [option.page])

  return (
    <div className="template-search-list">
      <div className="list-data container-wrap">
        <a id="0" />

        <div className="list-container container">
          <table>
            <thead>
              <tr>
                {columns.map((column: any) => {
                  return (
                    <th key={`column-${column.field}`}>
                      {column.name}
                    </th>
                  )
                })}
                {!!infoApi &&
                  <th className="th-detail" />
                }
              </tr>
            </thead>
            <tbody>
              {list.map((item: any) => {
                return (
                  <tr key={`item-${item._id}`}
                      className={detailId === item._id ? 'active' : ''}>
                    {columns.map((column: any) => {
                      return (
                        <td key={`item-${item._id}-column-${column.field}`}>
                          <UiData option={column}
                                  value={getData(item, column.field)} />
                        </td>
                      )
                    })}
                    {!!infoApi &&
                      <td className="td-detail">
                        <button onClick={() => setDetail(item)}>
                          <i className="xi-caret-up-circle xi-rotate-90" />
                        </button>
                      </td>
                    }
                  </tr>
                )
              })}
            <tr>
              {columns.map((column: any) => {
                return (
                  <td key={`gap-${column.field}`} />
                )
              })}
              {!!infoApi &&
                <td />
              }
            </tr>
            </tbody>
          </table>
        </div>

        <div className="list-navigation container-wrap only-mobile">
          <div className="navigation-container container">
            <UiNavigation pageNum={option.page + 1}
                          pageSize={option.count}
                          totalCount={count}
                          navSize={10}
                          onGoToPage={goToPage} />
          </div>
        </div>

        <a id="1" />

        {filter.length > 0 &&
          <div className={`search-container container ${detailId ? 'hidden' : ''}`}>
            <div className="search-options">
              {filter.map((filter: any) => {
                return (
                  <UiDataInput key={`filter-${filter.name}`}
                               options={filter}
                               onKeyUp={(e: any) => { if (e.key === 'Enter') search() }}
                               onChange={(v: any, k?: any) => setSearch(filter.field, v, k)} />
                )
              })}
            </div>
            <div className="search-button">
              <UiButton full={true}
                        onClick={search}>
                검색
              </UiButton>
            </div>
          </div>
        }

        <div className={`detail-container container ${detailId ? '' : 'hidden'}`}>
          {!editMode &&
            <button className="detail-close"
                    onClick={() => setDetail(null)}>
              <i className="xi-close-circle-o" />
            </button>
          }
          {editMode &&
            <button className="detail-close"
                    onClick={() => onSetEditMode(false)}>
              <i className="xi-close-circle-o" />
            </button>
          }
          <div className={`detail-items ${editMode ? 'edit-mode' : ''}`}>
            {detailMeta.map((meta: any) => {
              const field = meta.field as keyof typeof detailData
              let value = null
              if (!editMode || meta.static) {
                value = getData(detailData, field)
              } else {
                value = editValue.hasOwnProperty(field) ? editValue[field] : (detailData[field]) || ''
              }

              return (
                <div key={`meta-${meta.field}`}
                     className="detail-item">
                  {(!editMode || meta.static) &&
                    <>
                      <h6 className="detail-label">
                        {meta.name}
                      </h6>
                      <div className="detail-data">
                        <UiData value={value}
                                option={meta} />
                      </div>
                    </>
                  }
                  {(editMode && !meta.static) &&
                    <div className="detail-edit">
                      <UiDataInput options={meta}
                                   value={value}
                                   onChange={(v: any) => onSetEditValue(field, v)} />
                    </div>
                  }
                </div>
              )
            })}
          </div>
          {editMode &&
            <div className="edit-button">
              <UiButton full={true}
                        onClick={saveEdit}>
                저장
              </UiButton>
            </div>
          }
        </div>

        <a id="2" />

        <div className={`detail-container container ${insertMode ? '' : 'hidden'}`}>
          <button className="detail-close"
                  onClick={() => onSetInsertMode(false)}>
            <i className="xi-close-circle-o" />
          </button>

          <div className="detail-items edit-mode">
            {detailMeta.map((meta: any) => {
              const field = meta.field as keyof typeof detailData
              return (<>
                {!meta.static &&
                  <div key={`insert-meta-${meta.field}-${JSON.stringify(insertValue) === '{}'}`}
                       className="detail-item">
                    <div className="detail-edit">
                      <UiDataInput options={meta}
                                   creating={true}
                                   value={insertValue[field] || ''}
                                   onChange={(v: any) => onSetInsertValue(meta.field, v)}/>
                    </div>
                  </div>
                }
              </>)
            })}
          </div>
          <div className="edit-button">
            <UiButton full={true}
                      onClick={saveInsert}>
              생성
            </UiButton>
          </div>
        </div>
      </div>

      <div className="list-navigation container-wrap">
        <div className="navigation-container container only-pc">
          <UiNavigation pageNum={option.page + 1}
                        pageSize={option.count}
                        totalCount={count}
                        navSize={10}
                        onGoToPage={goToPage} />
        </div>
        <div className="button-container container">
          {!!insertApi &&
            <UiButton color="blue"
                      onClick={() => onSetInsertMode(true)}>
              <><i className="xi-plus" /> 생성</>
            </UiButton>
          }
          <div className={`button-detail ${detailId ? '' : 'hidden'} button-${detailCount}`}>
            {infoChildren &&
              <>{infoChildren(detailData, () => { loadList(); setDetail(detailData) })}</>
            }
            {!!deleteApi &&
              <UiButton color="white"
                        onClick={onDelete}>
                <><i className="xi-trash-o" /> 삭제</>
              </UiButton>
            }
            <UiButton color="white"
                      onClick={() => onSetEditMode(true)}>
              <><i className="xi-pen-o" /> 수정</>
            </UiButton>
          </div>
        </div>
      </div>
    </div>
  )  
}  

export default SearchList
