import React from 'react'

import { userList, userInfo, userUpsert, userDelete } from 'api/user'

import SearchList from 'components/template/SearchList'

const UserList = (): JSX.Element => {
  return (
    <div className="user-list">
      <SearchList listApi={userList}
                  infoApi={userInfo}
                  insertApi={userUpsert}
                  editApi={userUpsert}
                  deleteApi={userDelete} />
    </div>
  )  
}  

export default UserList
