import React, { ReactNode, createContext, useState, useContext } from 'react'

interface LoaderContextType {
  loading: boolean
  addLoading: () => void
  removeLoading: () => void
}

const LoaderContext = createContext<LoaderContextType>(null!)

export const LoaderProvider = ({ children }: { children: ReactNode }) => {

  let [loading, setLoading] = useState<boolean>(false)

  let addLoading = (): void => {
    setLoading(true)
  }

  let removeLoading = (): void => {
    setLoading(false)
  }

  let value = { loading, addLoading, removeLoading }

  return (
    <LoaderContext.Provider value={value}>
      {children}
    </LoaderContext.Provider>
  )
}

export const useLoader = () => {
  return useContext(LoaderContext)
}
