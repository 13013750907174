import httpClient from 'utils/http'

export function reportList (params: Object): Promise<any> {
  const url = '/r/l'

  return httpClient.post(url, params).then(response => {
    return response.data  
  })  
}

export function reportInfo (params: Object): Promise<any> {
  const url = '/r/i'

  return httpClient.post(url, params).then(response => {
    return response.data
  })
}
