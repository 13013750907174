import React from 'react'

import { purchaseList, purchaseInfo, purchaseUpsert } from 'api/purchase'

import SearchList from 'components/template/SearchList'

const PurchaseList = (): JSX.Element => {
  return (
    <div className="place-list">
      <SearchList listApi={purchaseList}
                  infoApi={purchaseInfo}
                  insertApi={purchaseUpsert}
                  editApi={purchaseUpsert} />
    </div>
  )  
}  

export default PurchaseList
