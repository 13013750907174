import React from 'react'

import 'assets/styles/base.scss'
import 'assets/styles/icon.scss'
import 'assets/styles/wysiwyg.scss'

import Router from 'routes'

const App = (): JSX.Element => {
  return (
    <Router />
  )
}

export default App
