import httpClient from 'utils/http'

export function translationList (params: Object): Promise<any> {
  const url = '/tr/l'

  return httpClient.post(url, params).then(response => {
    return response.data
  })
}

export function translationInfo (params: Object): Promise<any> {
  const url = '/tr/i'

  return httpClient.post(url, params).then(response => {
    return response.data
  })
}

export function translationUpsert (params: Object): Promise<any> {
  const url = '/tr/u'

  return httpClient.post(url, params).then(response => {
    return response.data
  })
}
