import React from 'react'
import { BrowserRouter, Navigate, useRoutes, useLocation } from 'react-router-dom'

import BlankLayout from 'layouts/BlankLayout'
import DefaultLayout from 'layouts/DefaultLayout'

import SignIn from 'pages/SignIn'
import Dashboard from 'pages/Dashboard'

import UserList from 'pages/User/List'
import InterestList from 'pages/User/InterestList'
import InterestCategoryList from 'pages/User/InterestCategoryList'

import ArchiveList from 'pages/Archive/List'
import ArchiveCategoryList from 'pages/Archive/CategoryList'
import ArchiveArticleList from 'pages/Archive/ArticleList'

import CommunityList from 'pages/Communuty/List'
import CommunityRequestList from 'pages/Communuty/Request'
import CommunityPostList from 'pages/Communuty/PostList'
import CommunityCommentList from 'pages/Communuty/CommentList'
import CommunityPickList from 'pages/Communuty/PickList'

import PlaceList from 'pages/Place/List'

import RoomList from 'pages/Talk/List'
import RoomRequestList from 'pages/Talk/RequestList'
import RoomKeywordList from 'pages/Talk/KeywordList'
import ChatList from 'pages/Talk/ChatList'

import PurchaseList from 'pages/Purchase/List'
import PurchaseItemList from 'pages/Purchase/ItemList'

import TranslationList from 'pages/Translation/List'

import BannerList from 'pages/Banner/List'

import ReportList from 'pages/Report/List'

import AdminList from 'pages/Admin/List'

import { AuthProvider, useAuth } from 'context/Auth'
import { LoaderProvider } from 'context/Loader'

const OnlyMember = ({ children }: { children: JSX.Element }) => {
  let auth = useAuth()
  let location = useLocation()

  if (!auth.user) {
    return <Navigate to="/" state={{ from: location }} />
  }

  return children
}

const OnlyGuest = ({ children }: { children: JSX.Element }) => {
  let auth = useAuth()
  let location = useLocation()

  if (auth.user) {
    return <Navigate to="/dashboard" state={{ from: location }} />
  }

  return children
}

const Routes = () => {
  let routes = useRoutes([
    { path: '/', element: (<OnlyGuest><BlankLayout><SignIn /></BlankLayout></OnlyGuest>) },
    { path: '/dashboard', element: (<OnlyMember><DefaultLayout title="Dashboard"><Dashboard /></DefaultLayout></OnlyMember>) },

    { path: '/u', element: (<OnlyMember><DefaultLayout title="User"><UserList /></DefaultLayout></OnlyMember>) },
    { path: '/u/l', element: (<OnlyMember><DefaultLayout title="User"><UserList /></DefaultLayout></OnlyMember>) },
    { path: '/u/i', element: (<OnlyMember><DefaultLayout title="User | Interest"><InterestList /></DefaultLayout></OnlyMember>) },
    { path: '/u/ic', element: (<OnlyMember><DefaultLayout title="User | Interest Category"><InterestCategoryList /></DefaultLayout></OnlyMember>) },

    { path: '/a', element: (<OnlyMember><DefaultLayout title="Archive"><ArchiveList /></DefaultLayout></OnlyMember>) },
    { path: '/a/l', element: (<OnlyMember><DefaultLayout title="Archive"><ArchiveList /></DefaultLayout></OnlyMember>) },
    { path: '/a/c', element: (<OnlyMember><DefaultLayout title="Archive | Category"><ArchiveCategoryList /></DefaultLayout></OnlyMember>) },
    { path: '/a/a', element: (<OnlyMember><DefaultLayout title="Archive | Article"><ArchiveArticleList /></DefaultLayout></OnlyMember>) },

    { path: '/b', element: (<OnlyMember><DefaultLayout title="Community"><CommunityList /></DefaultLayout></OnlyMember>) },
    { path: '/b/l', element: (<OnlyMember><DefaultLayout title="Community"><CommunityList /></DefaultLayout></OnlyMember>) },
    { path: '/b/r', element: (<OnlyMember><DefaultLayout title="Community | Request"><CommunityRequestList /></DefaultLayout></OnlyMember>) },
    { path: '/b/p', element: (<OnlyMember><DefaultLayout title="Community | Post"><CommunityPostList /></DefaultLayout></OnlyMember>) },
    { path: '/b/k', element: (<OnlyMember><DefaultLayout title="Community | Post | Picked"><CommunityPickList /></DefaultLayout></OnlyMember>) },
    { path: '/b/c', element: (<OnlyMember><DefaultLayout title="Community | Comment"><CommunityCommentList /></DefaultLayout></OnlyMember>) },

    { path: '/p', element: (<OnlyMember><DefaultLayout title="Docent"><PlaceList /></DefaultLayout></OnlyMember>) },
    { path: '/p/l', element: (<OnlyMember><DefaultLayout title="Docent | Place"><PlaceList /></DefaultLayout></OnlyMember>) },

    { path: '/t/r', element: (<OnlyMember><DefaultLayout title="Chat | Room"><RoomList /></DefaultLayout></OnlyMember>) },
    { path: '/t/r/l', element: (<OnlyMember><DefaultLayout title="Chat | Room"><RoomList /></DefaultLayout></OnlyMember>) },
    { path: '/t/r/r', element: (<OnlyMember><DefaultLayout title="Chat | Room Request"><RoomRequestList /></DefaultLayout></OnlyMember>) },
    { path: '/t/r/k', element: (<OnlyMember><DefaultLayout title="Chat | Room Keyword"><RoomKeywordList /></DefaultLayout></OnlyMember>) },
    { path: '/t/c', element: (<OnlyMember><DefaultLayout title="Chat | Message"><ChatList /></DefaultLayout></OnlyMember>) },

    { path: '/pc', element: (<OnlyMember><DefaultLayout title="Purchase"><PurchaseList /></DefaultLayout></OnlyMember>) },
    { path: '/pc/l', element: (<OnlyMember><DefaultLayout title="Purchase"><PurchaseList /></DefaultLayout></OnlyMember>) },
    { path: '/pc/i', element: (<OnlyMember><DefaultLayout title="Purchase | Item"><PurchaseItemList /></DefaultLayout></OnlyMember>) },

    { path: '/tr', element: (<OnlyMember><DefaultLayout title="Translation"><TranslationList /></DefaultLayout></OnlyMember>) },

    { path: '/bn', element: (<OnlyMember><DefaultLayout title="Banner"><BannerList /></DefaultLayout></OnlyMember>) },

    { path: '/r', element: (<OnlyMember><DefaultLayout title="Report"><ReportList /></DefaultLayout></OnlyMember>) },

    { path: '/m', element: (<OnlyMember><DefaultLayout title="Admin"><AdminList /></DefaultLayout></OnlyMember>) }
  ])  

  return routes  
}  

const Router = (): JSX.Element => {
  return (
    <LoaderProvider>
      <AuthProvider>
        <BrowserRouter>
          <Routes />
        </BrowserRouter>
      </AuthProvider>
    </LoaderProvider>
  )  
}  

export default Router  
