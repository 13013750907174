import httpClient from 'utils/http'

export function bannerList (params: Object): Promise<any> {
  const url = '/bn/l'

  return httpClient.post(url, params).then(response => {
    return response.data
  })
}

export function bannerInfo (params: Object): Promise<any> {
  const url = '/bn/i'

  return httpClient.post(url, params).then(response => {
    return response.data
  })
}

export function bannerUpsert (params: Object): Promise<any> {
  const url = '/bn/u'

  return httpClient.post(url, params).then(response => {
    return response.data
  })
}

export function bannerDelete (params: Object): Promise<any> {
  const url = '/bn/d'

  return httpClient.post(url, params).then(response => {
    return response.data
  })
}