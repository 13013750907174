import React from 'react'

import { interestCategoryList, interestCategoryInfo, interestCategoryUpsert, interestCategoryDelete } from 'api/user'

import SearchList from 'components/template/SearchList'

const InterestCategoryList = (): JSX.Element => {
  return (
    <div className="interest-category-list">
      <SearchList listApi={interestCategoryList}
                  infoApi={interestCategoryInfo}
                  insertApi={interestCategoryUpsert}
                  editApi={interestCategoryUpsert}
                  deleteApi={interestCategoryDelete} />
    </div>
  )  
}  

export default InterestCategoryList
