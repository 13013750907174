import React from 'react'
import ReactQuill from 'react-quill';
import { format } from 'date-fns'
import { utcToZonedTime } from 'date-fns-tz'
import { getS3Url } from 'utils/tool'

import UiInput from 'components/ui/UiInput'
import UiMap from 'components/ui/UiMap'
import UiImage from 'components/ui/Image'
import UiImages from 'components/ui/Images'

import 'react-quill/dist/quill.snow.css'
import UiButton from "./UiButton";

const UiDataInput = ({ value, options, creating, onChange, onKeyUp }
                       : { value?: any, options: any, creating?: boolean, onChange?: Function, onKeyUp?: any }): JSX.Element => {
  let element = <></>

  let type = options.type
  let label = options.name
  let range = options.range || false
  let description = options.description || undefined

  if (type[0] !== 'file' && options.array) {
    value = (value || []).join('||')
  }

  if (options.range) {
    value = (value || {})
  }

  if (type[0] === 'timeRange') {
    value = (value || [])
  }

  const quillModules = {
    toolbar: [
      ['bold', 'italic', 'underline', 'strike'],
      [{ 'list': 'ordered' }, { 'list': 'bullet' }, { 'indent': '-1' }, { 'indent': '+1' }],
      ['image'],
      [{ 'color': [] }, { 'background': [] }],
      [{ 'align': [] }],
      ['clean']
    ]
  }

  const setDate = (value: any) => {
    let tz: number = -parseInt(type[1].substr(3, 2))
    let tzString: string = tz.toString()
    if (tz >= 0) {
      tzString = '+' + tzString
    }

    if (type[0] === 'date') {
      value = format(utcToZonedTime(value, 'Etc/GMT' + tzString), 'yyyy-MM-dd')
    } else if (type[0] === 'datetime') {
      value = format(utcToZonedTime(value, 'Etc/GMT' + tzString), "yyyy-MM-dd'T'HH:mm:ss")
    }
    return value
  }

  if (type[0] === 'date' || type[0] === 'datetime') {
    if (options.range) {
      if (value['$lte']) {
        value['$lte'] = setDate(value['$lte'])
      }
      if (value['$gte']) {
        value['$gte'] = setDate(value['$gte'])
      }
    } else {
      if (value) {
        value = setDate(value)
      }
    }
  }

  const setTime = (action: string, row: number, k?: string, v?: any): void => {
    if (onChange) {
      if (action === 'insert') {
        value.splice(row, 0, {d: 'ho', s: '00:00', e: '00:00'})
      } else if (action === 'remove') {
        value.splice(row, 1)
      } else if (k) {
        value[row][k] = v
      }
      onChange(value)
    }
  }

  const days = [
    { k: "공", v: "ho" },
    { k: "월", v: "mo" },
    { k: "화", v: "tu" },
    { k: "수", v: "we" },
    { k: "목", v: "th" },
    { k: "금", v: "fr" },
    { k: "토", v: "sa" },
    { k: "일", v: "su" }
  ]

  const onDataChange = (v: any, k?: string): void => {
    if (onChange) {
      if (type[0] !== 'file' && options.array) {
        v = v.split('||')
      }
      onChange(v, k)
    }
  }

  const onDataLangChange = (lang: string, v: any): void => {
    if (onChange) {
      if (type[0] !== 'file' && options.array) {
        v = v.split('||')
      }
      onChange({
        ...value,
        [lang]: v
      })
    }
  }

  if (options.options) {
    element = <div className="ui-input ui-select">
                <h6 className="ui-input-label">
                  {label}
                </h6>
                <select onChange={(e: any) => onDataChange(e.target.value)}>
                  <option value=""
                          selected={!value}>
                    (선택 안 함)
                  </option>
                  {options.options.map((option: any) => {
                    return <option value={option.value}
                                   selected={value === option.value}>
                      {option.text}
                    </option>
                  })}
                </select>
              </div>
  } else {
    if (type[0] === 'string' || type[0] === 'user') {
      if (options.translation && options.translation.length > 0) {
        element = options.translation.map((lang: any) => {
          const v = value && value.hasOwnProperty(lang) ? value[lang] : null
          if (type[1] === 'wysiwyg') {
            return (
              <div className="ui-input">
                <h6 className="ui-input-label">
                  <span className="tip">{lang}</span> {label}
                </h6>
                <ReactQuill className="wysiwyg"
                            theme="snow"
                            value={v}
                            modules={quillModules}
                            onChange={(e: any) => {
                              if (v !== e) {
                                onDataLangChange(lang, e)
                              }
                            }}/>
              </div>
            )
          } else {
            return <UiInput type={type[1] === 'long' ? 'textarea' : 'text'}
                            label={<><span className="tip">{lang}</span> {label}</>}
                            description={(options.array ? '||로 구분합니다.' : '') + (description || '')}
                            value={v}
                            onKeyUp={onKeyUp}
                            onChange={(e: any) => onDataLangChange(lang, e.target.value)}/>
          }
        })
      } else {
        if (type[1] === 'wysiwyg') {
          element = (
            <div className="ui-input">
              <ReactQuill className="wysiwyg"
                          theme="snow"
                          value={value}
                          modules={quillModules}
                          onChange={(e: any) => {
                            if (value !== e) {
                              onDataChange(e)
                            }
                          }}/>
            </div>
          )
        } else {
          element = <UiInput type={type[1] === 'long' ? 'textarea' : 'text'}
                             description={(options.array ? '||로 구분합니다.' : '') + (description || '')}
                             label={label}
                             value={value}
                             onKeyUp={onKeyUp}
                             onChange={(e: any) => onDataChange(e.target.value)}/>
        }
      }
    } else if (type[0] === 'boolean') {
      if (options.translation && options.translation.length > 0) {
        element = options.translation.map((lang: any) => {
          return <UiInput type="checkbox"
                          description={(options.array ? '||로 구분합니다.' : '') + (description || '')}
                          label={<><span className="tip">{lang}</span> {label}</>}
                          checked={value && value.hasOwnProperty(lang) ? value[lang] : false}
                          onChange={(e: any) => onDataLangChange(lang, !value)}/>
        })
      } else {
        element = <UiInput type="checkbox"
                           description={(options.array ? '||로 구분합니다.' : '') + (description || '')}
                           label={label}
                           checked={value}
                           onChange={(e: any) => onDataChange(!value)}/>
      }
    } else if (type[0] === 's3') {
      if (options.translation && options.translation.length > 0) {
        element = options.translation.map((lang: any) => {
          return <UiInput type="text"
                          description={(options.array ? '||로 구분합니다.' : '') + (description || '')}
                          label={<><span className="tip">{lang}</span> {label}</>}
                          value={value && value.hasOwnProperty(lang) ? value[lang] : null}
                          onKeyUp={onKeyUp}
                          onChange={(e: any) => onDataLangChange(lang, e.target.value)}/>
        })
      } else {
        element = <UiInput type="text"
                           description={(options.array ? '||로 구분합니다.' : '') + (description || '')}
                           label={label}
                           value={value}
                           onKeyUp={onKeyUp}
                           onChange={(e: any) => onDataChange(e.target.value)}/>
      }
    } else if (type[0] === 'type') {
      element = <UiInput type="text"
                         description={(options.array ? '||로 구분합니다.' : '') + (description || '')}
                         label={label}
                         value={value}
                         onKeyUp={onKeyUp}
                         onChange={(e: any) => onDataChange(e.target.value)}/>
    } else if (type[0] === 'number') {
      element = <UiInput type="number"
                         description={(options.array ? '||로 구분합니다.' : '') + (description || '')}
                         label={label}
                         value={value}
                         onKeyUp={onKeyUp}
                         onChange={(e: any) => onDataChange(e.target.value)}/>
    } else if (type[0] === 'translation') {
      element = <UiInput type="text"
                         description={(options.array ? '||로 구분합니다.' : '') + (description || '')}
                         label={label}
                         value={value._id}
                         onKeyUp={onKeyUp}
                         onChange={(e: any) => onDataChange(e.target.value)}/>
    } else if (type[0] === 'date') {
      if (range) {
        element = <span className="ui-input-range">
                  <UiInput type="date"
                           label={label}
                           description="~"
                    // value={value['$gte'] || value}
                           onKeyUp={onKeyUp}
                           onChange={(e: any) => onDataChange(e.target.value, '$gte')}/>
                  <UiInput type="date"
                    // value={value['$lte'] || value}
                           onKeyUp={onKeyUp}
                           onChange={(e: any) => onDataChange(e.target.value, '$lte')}/>
                </span>
      } else {
        element = <UiInput type="date"
                           description={(options.array ? '||로 구분합니다.' : '') + (description || '')}
                           label={label}
                           value={value}
                           onKeyUp={onKeyUp}
                           onChange={(e: any) => onDataChange(e.target.value)}/>
      }
    } else if (type[0] === 'datetime') {
      element = <UiInput type="datetime-local"
                         description={(options.array ? '||로 구분합니다.' : '') + (description || '')}
                         label={label}
                         value={value}
                         onKeyUp={onKeyUp}
                         onChange={(e: any) => onDataChange(e.target.value)}/>
    } else if (type[0] === 'timeRange') {
      return (
        <div className="ui-input ui-time-range">
          <h6 className="ui-input-label">
            {label}
          </h6>
          {value.map((range: any, i: number) => {
            return (
              <div className="ui-time-item">
                <div className="item-values">
                  <div className="ui-input ui-select">
                    <select onChange={(e: any) => setTime('', i, 'd', e.target.value)}>
                      <option value=""
                              selected={!range.d}>
                        &nbsp;
                      </option>
                      {days.map((day: any) => {
                        return <option value={day.v}
                                       selected={range.d === day.v}>
                          {day.k}
                        </option>
                      })}
                    </select>
                  </div>
                  <UiInput type='time'
                           value={range.s}
                           onKeyUp={onKeyUp}
                           onChange={(e: any) => setTime('', i, 's', e.target.value)} />
                  ~&nbsp;
                  <UiInput type='time'
                           value={range.e}
                           onKeyUp={onKeyUp}
                           onChange={(e: any) => setTime('', i, 'e', e.target.value)} />
                </div>
                <UiButton color="black"
                          onClick={() => { setTime('remove', i) }}>
                  <i className="xi-minus" />
                </UiButton>
                <UiButton color="blue"
                          onClick={() => { setTime('insert', i) }}>
                  <i className="xi-plus" />
                </UiButton>
              </div>
            )
          })}
          <div className="ui-time-item">
            <UiButton color="blue"
                      onClick={() => { setTime('insert', value.length + 1) }}>
              <i className="xi-plus" />
            </UiButton>
          </div>
        </div>
      )
      element = <UiInput type="datetime-local"
                         description={(options.array ? '||로 구분합니다.' : '') + (description || '')}
                         label={label}
                         value={value}
                         onKeyUp={onKeyUp}
                         onChange={(e: any) => onDataChange(e.target.value)}/>
    } else if (type[0] === 'file') {
      if (options.array) {
        if (options.translation && options.translation.length > 0) {
          element = options.translation.map((lang: any) => {
            let v = value && value.hasOwnProperty(lang) ? value[lang] : null
            let onAddFile = (file: any) => {
              if (!v) v = []
              onDataLangChange(lang, [
                ...v,
                file
              ])
            }
            let onRemoveFile = (index: number) => {
              if (!v) v = []
              v.splice(index, 1)
              onDataLangChange(lang, [
                ...v
              ])
            }

            return (
              <div className="ui-input">
                <h6 className="ui-input-label">
                  <span className="tip">{lang}</span> {label}
                </h6>
                <UiImages v={v ? v.map((a: any) => getS3Url(a.key)) : []}
                          initialFiles={v ? v.map((a: any) => getS3Url(a.key)) : []}
                          resizeSize={{ height: 750 }}
                          onAddFile={onAddFile}
                          onRemoveFile={onRemoveFile} />
              </div>
            )
          })
        } else {
          let onAddFile = (file: any) => {
            if (!value) value = []
            onDataChange([
              ...value,
              file
            ])
          }
          let onRemoveFile = (index: number) => {
            if (!value) value = []
            value.splice(index, 1)
            onDataChange([
              ...value
            ])
          }

          element = <div className="ui-input">
            <h6 className="ui-input-label">
              {label}
            </h6>
            <UiImages value={value ? value.map((v: any) => getS3Url(v.key)) : []}
                      initialFiles={value ? value.map((v: any) => getS3Url(v.key)) : []}
                      resizeSize={{ height: 750 }}
                      onAddFile={onAddFile}
                      onRemoveFile={onRemoveFile} />
          </div>
        }
      } else {
        if (options.translation && options.translation.length > 0) {
          element = options.translation.map((lang: any) => {
            let v = value && value.hasOwnProperty(lang) ? value[lang] : null
            return (
              <div className="ui-input">
                <h6 className="ui-input-label">
                  <span className="tip">{lang}</span> {label}
                </h6>
                <UiImage v={v ? getS3Url(v.key) : null}
                         type={v ? v.type : null}
                         resizeSize={{width: 1024, height: 1024}}
                         width="128px"
                         height="128px"
                         placeholder={v ? v.originalFileName : null}
                         description=""
                         onChange={(v: any) => onDataLangChange(lang, v)}/>
              </div>
            )
          })
        } else {
          element = <div className="ui-input">
            <h6 className="ui-input-label">
              {label}
            </h6>
            <UiImage value={value ? getS3Url(value.key) : null}
                     type={value ? value.type : null}
                     resizeSize={{width: 1024, height: 1024}}
                     width="128px"
                     height="128px"
                     placeholder={value.originalFileName}
                     description=""
                     onChange={(value: any) => onDataChange(value)}/>
          </div>
        }
      }

    } else if (type[0] === 'coord') {
      if (creating && !value) {
        value = '126.9771833 37.5722304'
      }
      if (value) {
        const splited = value.split(' ')
        const v = {
          lat: parseFloat(splited[1]),
          lng: parseFloat(splited[0])
        }
        element = <UiMap value={v}
                         label={label}
                         onChange={(coord: { lat: number, lng: number }) => onDataChange(`${coord.lng} ${coord.lat}`)}/>
      }
    }
  }

  return (
    <span className="ui-data-input">
      {element}
    </span>
  )
}

export default UiDataInput
