import httpClient from 'utils/http'

export function archiveList (params: Object): Promise<any> {
  const url = '/a/l'

  return httpClient.post(url, params).then(response => {
    return response.data
  })
}

export function archiveInfo (params: Object): Promise<any> {
  const url = '/a/i'

  return httpClient.post(url, params).then(response => {
    return response.data
  })
}

export function archiveUpsert (params: Object): Promise<any> {
  const url = '/a/u'

  return httpClient.post(url, params).then(response => {
    return response.data
  })
}

export function archiveDelete (params: Object): Promise<any> {
  const url = '/a/d'

  return httpClient.post(url, params).then(response => {
    return response.data
  })
}

export function archiveCategoryList (params: Object): Promise<any> {
  const url = '/a/c/l'

  return httpClient.post(url, params).then(response => {
    return response.data
  })
}

export function archiveCategoryInfo (params: Object): Promise<any> {
  const url = '/a/c/i'

  return httpClient.post(url, params).then(response => {
    return response.data
  })
}

export function archiveCategoryUpsert (params: Object): Promise<any> {
  const url = '/a/c/u'

  return httpClient.post(url, params).then(response => {
    return response.data
  })
}

export function archiveCategoryDelete (params: Object): Promise<any> {
  const url = '/a/c/d'

  return httpClient.post(url, params).then(response => {
    return response.data
  })
}

export function archiveArticleList (params: Object): Promise<any> {
  const url = '/a/a/l'

  return httpClient.post(url, params).then(response => {
    return response.data
  })
}

export function archiveArticleInfo (params: Object): Promise<any> {
  const url = '/a/a/i'

  return httpClient.post(url, params).then(response => {
    return response.data
  })
}

export function archiveArticleUpsert (params: Object): Promise<any> {
  const url = '/a/a/u'

  return httpClient.post(url, params).then(response => {
    return response.data
  })
}

export function archiveArticleDelete (params: Object): Promise<any> {
  const url = '/a/a/d'

  return httpClient.post(url, params).then(response => {
    return response.data
  })
}
