import React from 'react'

import { purchaseItemList, purchaseItemInfo, purchaseItemUpsert } from 'api/purchase'

import SearchList from 'components/template/SearchList'

const PurchaseList = (): JSX.Element => {
  return (
    <div className="place-list">
      <SearchList listApi={purchaseItemList}
                  infoApi={purchaseItemInfo}
                  insertApi={purchaseItemUpsert}
                  editApi={purchaseItemUpsert} />
    </div>
  )  
}  

export default PurchaseList
