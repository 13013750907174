import React, { MouseEventHandler } from 'react'

import './UiButton.scss'

const UiInput = ({ children, disabled, full, color, onClick }
  :{ children?: JSX.Element | string,
     disabled?: boolean,
     full?: boolean,
     color?: string,
     onClick?: MouseEventHandler<HTMLButtonElement> }): JSX.Element => {

  if (!color) {
   color = 'black'
  }

  return (
    <div className={`ui-button ${full ? 'full' : ''}`}>
      <button className={['ui-input-element', full ? 'full' : '', `color-${color}`].join(' ')}
              disabled={disabled}
              onClick={onClick}>
        {children}
      </button>
    </div>
  )
}

export default UiInput
