import React from 'react'

import { adminList, adminInfo, adminUpsert, adminDelete } from 'api/admin'

import SearchList from 'components/template/SearchList'

const AdminList = (): JSX.Element => {
  return (
    <div className="admin-list">
      <SearchList listApi={adminList}
                  infoApi={adminInfo}
                  insertApi={adminUpsert}
                  editApi={adminUpsert}
                  deleteApi={adminDelete} />
    </div>
  )  
}  

export default AdminList
