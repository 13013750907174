import httpClient from 'utils/http'

export function roomList (params: Object): Promise<any> {
  const url = '/t/r/l'

  return httpClient.post(url, params).then(response => {
    return response.data
  })
}

export function roomInfo (params: Object): Promise<any> {
  const url = '/t/r/i'

  return httpClient.post(url, params).then(response => {
    return response.data
  })
}

export function roomDelete (params: Object): Promise<any> {
  const url = '/t/r/d'

  return httpClient.post(url, params).then(response => {
    return response.data
  })
}

export function roomRequestList (params: Object): Promise<any> {
  const url = '/t/r/r/l'

  return httpClient.post(url, params).then(response => {
    return response.data
  })
}

export function roomRequestInfo (params: Object): Promise<any> {
  const url = '/t/r/r/i'

  return httpClient.post(url, params).then(response => {
    return response.data
  })
}

export function roomRequestDelete (params: Object): Promise<any> {
  const url = '/t/r/r/d'

  return httpClient.post(url, params).then(response => {
    return response.data
  })
}

export function roomKeywordList (params: Object): Promise<any> {
  const url = '/t/r/k/l'

  return httpClient.post(url, params).then(response => {
    return response.data
  })
}

export function roomKeywordInfo (params: Object): Promise<any> {
  const url = '/t/r/k/i'

  return httpClient.post(url, params).then(response => {
    return response.data
  })
}

export function roomKeywordUpsert (params: Object): Promise<any> {
  const url = '/t/r/k/u'

  return httpClient.post(url, params).then(response => {
    return response.data
  })
}

export function roomKeywordDelete (params: Object): Promise<any> {
  const url = '/t/r/k/d'

  return httpClient.post(url, params).then(response => {
    return response.data
  })
}

export function chatList (params: Object): Promise<any> {
  const url = '/t/c/l'

  return httpClient.post(url, params).then(response => {
    return response.data
  })
}

export function chatInfo (params: Object): Promise<any> {
  const url = '/t/c/i'

  return httpClient.post(url, params).then(response => {
    return response.data
  })
}
