import React from 'react'

import { bannerList, bannerInfo, bannerUpsert, bannerDelete } from 'api/banner'

import SearchList from 'components/template/SearchList'

const banneList = (): JSX.Element => {
  return (
    <div className="banner-list">
      <SearchList listApi={bannerList}
                  infoApi={bannerInfo}
                  insertApi={bannerUpsert}
                  editApi={bannerUpsert}
                  deleteApi={bannerDelete} />
    </div>
  )  
}  

export default banneList
