import React from 'react'

import { reportList, reportInfo } from 'api/report'

import SearchList from 'components/template/SearchList'

const ReportList = (): JSX.Element => {
  return (
    <div className="report-list">
      <SearchList listApi={reportList}
                  infoApi={reportInfo} />
    </div>
  )  
}  

export default ReportList
