import { REACT_APP_GOOGLE_MAP_API_KEY } from '../../config'

import React from 'react'
import GoogleMap from 'google-map-react'

import './UiMap.scss'

const UiInput = ({ label, value, disabled, description, error, onChange }
  :{ label?: string,
     value?: { lat: number, lng: number },
     disabled: boolean,
     description?: string,
     error?: string,
     onChange: Function }): JSX.Element => {

  let map: any = null

  const setMap = (m: any) => {
    map = m.map
    if (value && value.lat && value.lng) {
      map.panTo(value)
    }
  }

  const centerChanged = ({ center }: { center: { lat: number, lng: number } }) => {
    if (center.lat && center.lng) {
      onChange(center)
    }
  }

  return (
    <div className="ui-map">
      {!!label &&
        <h6 className="ui-input-label">
          {label}
        </h6>
      }
      <div className="ui-map-element">
        {value &&
          <GoogleMap bootstrapURLKeys={{key: REACT_APP_GOOGLE_MAP_API_KEY}}
                     defaultZoom={17}
                     defaultCenter={{ lat: 0, lng: 0 }}
                     options={{
                       clickableIcons: false,
                       disableDefaultUI: true,
                       backgroundColor: '#F6F6FA',
                       gestureHandling: disabled ? 'none' : 'greedy'
                     }}
                     yesIWantToUseGoogleMapApiInternals={true}
                     onGoogleApiLoaded={(map: any) => setMap(map)}
                     onChange={centerChanged}/>
        }
        <span className="pin">
            <svg width="44" height="52" viewBox="0 0 44 52" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path d="M2,20c0,1.7,0.2,2.9,0.6,4.5C4.5,32.4,11,41.7,22,48c11-6.3,17.5-15.6,19.4-23.5c0.4-1.5,0.6-2.8,0.6-4.5M22,28c-4.4,0-8-3.6-8-8h16C30,24.4,26.4,28,22,28z" fill="#3154FF"/>
            </svg>
          </span>
      </div>
      {!error &&
        <p className="ui-input-description">
          {value &&
            <>{value.lat}, {value.lng}</>
          }
          {description}
        </p>
      }
      {!!error &&
        <p className="ui-input-description error">
          {error}
        </p>
      }
    </div>
  )
}

UiInput.defaultProps = {
  disabled: false,
  onChange: () => {}
}

export default UiInput
