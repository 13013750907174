import httpClient from 'utils/http'

export function boardList (params: Object): Promise<any> {
  const url = '/b/l'

  return httpClient.post(url, params).then(response => {
    return response.data
  })
}

export function boardInfo (params: Object): Promise<any> {
  const url = '/b/i'

  return httpClient.post(url, params).then(response => {
    return response.data
  })
}

export function boardUpsert (params: Object): Promise<any> {
  const url = '/b/u'

  return httpClient.post(url, params).then(response => {
    return response.data
  })
}

export function boardDelete (params: Object): Promise<any> {
  const url = '/b/d'

  return httpClient.post(url, params).then(response => {
    return response.data
  })
}

export function boardRequestList (params: Object): Promise<any> {
  const url = '/b/r/l'

  return httpClient.post(url, params).then(response => {
    return response.data
  })
}

export function boardRequestInfo (params: Object): Promise<any> {
  const url = '/b/r/i'

  return httpClient.post(url, params).then(response => {
    return response.data
  })
}

export function boardRequestCheck (params: Object): Promise<any> {
  const url = '/b/r/c'

  return httpClient.post(url, params).then(response => {
    return response.data
  })
}

export function boardPostList (params: Object): Promise<any> {
  const url = '/b/p/l'

  return httpClient.post(url, params).then(response => {
    return response.data
  })
}

export function boardPostInfo (params: Object): Promise<any> {
  const url = '/b/p/i'

  return httpClient.post(url, params).then(response => {
    return response.data
  })
}

export function boardPostDelete (params: Object): Promise<any> {
  const url = '/b/p/d'

  return httpClient.post(url, params).then(response => {
    return response.data
  })
}

export function boardPostPick (params: Object): Promise<any> {
  const url = '/b/p/p'

  return httpClient.post(url, params).then(response => {
    return response.data
  })
}

export function boardPostPickList (params: Object): Promise<any> {
  const url = '/b/p/p/l'

  return httpClient.post(url, params).then(response => {
    return response.data
  })
}

export function boardCommentList (params: Object): Promise<any> {
  const url = '/b/c/l'

  return httpClient.post(url, params).then(response => {
    return response.data
  })
}

export function boardCommentInfo (params: Object): Promise<any> {
  const url = '/b/c/i'

  return httpClient.post(url, params).then(response => {
    return response.data
  })
}

export function boardCommentDelete (params: Object): Promise<any> {
  const url = '/b/c/d'

  return httpClient.post(url, params).then(response => {
    return response.data
  })
}
