import React from 'react'

import { archiveArticleList, archiveArticleInfo, archiveArticleUpsert, archiveArticleDelete } from 'api/archive'

import SearchList from 'components/template/SearchList'

const ArchiveArticleList = (): JSX.Element => {
  return (
    <div className="archive-article-list">
      <SearchList listApi={archiveArticleList}
                  infoApi={archiveArticleInfo}
                  insertApi={archiveArticleUpsert}
                  editApi={archiveArticleUpsert}
                  deleteApi={archiveArticleDelete} />
    </div>
  )
}

export default ArchiveArticleList
