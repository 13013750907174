import React, { useState } from 'react'
import { NavLink } from 'react-router-dom'

import { useAuth } from 'context/Auth'
import { useLoader } from 'context/Loader'

import UiButton from 'components/ui/UiButton'

import './DefaultLayout.scss'

const DefaultLayout = ({ children, title }
  : { children?: JSX.Element,
      title?: string }): JSX.Element => {
  let auth = useAuth()
  let loader = useLoader()

  let user = auth.user
  const [sidebarOpen, setSidebarOpen] = useState<number>(0)

  let menu: Array<any> = [
    { to: '/dashboard', label: 'Dashboard' }
  ]

  let logout = () => {
    auth.signOut()
  }

  let toggleSidebar = () => {
    if (sidebarOpen === -1) {
      setSidebarOpen(1)
    } else {
      setSidebarOpen(-1)
    }
  }

  if (user.levelUser) {
    menu.push({ to: '/u', label: 'User' , children: [
        { to: '/u/l', label: 'User' },
        { to: '/u/i', label: 'Interest' },
        { to: '/u/ic', label: 'Interest Category' },
      ] })
  }
  if (user.levelArchive) {
    menu.push({ to: '/a', label: 'Archive', children: [
        { to: '/a/l', label: 'Archive' },
        { to: '/a/c', label: 'Category' },
        { to: '/a/a', label: 'Article' },
      ] })
  }
  if (user.levelCommunity) {
    menu.push({ to: '/b', label: 'Community', children: [
        { to: '/b/l', label: 'Board' },
        { to: '/b/r', label: 'Request' },
        { to: '/b/p', label: 'Post' },
        { to: '/b/c', label: 'Comment' },
        { to: '/b/k', label: 'PICK' },
      ] })
  }
  if (user.levelDocent) {
    menu.push({ to: '/p', label: 'Docent', children: [
        { to: '/p/l', label: 'Place' },
      ] })
  }
  if (user.levelChat) {
    menu.push({ to: '/t/r', label: 'Chat', children: [
        { to: '/t/r/l', label: 'Room' },
        { to: '/t/r/r', label: 'Request' },
        { to: '/t/r/k', label: 'Keyword' },
        { to: '/t/c', label: 'Message' },
      ] })
  }
  if (user.levelPurchase) {
    menu.push({ to: '/pc', label: 'Purchase', children: [
        { to: '/pc/l', label: 'Purchase' },
        { to: '/pc/i', label: 'Item' },
      ] })
  }
  if (user.levelTranslation) {
    menu.push({ to: '/tr', label: 'Translation' })
  }
  if (user.levelBanner) {
    menu.push({ to: '/bn', label: 'Banner' })
  }
  if (user.levelReport) {
    menu.push({ to: '/r', label: 'Report' })
  }
  if (user.levelAdmin) {
    menu.push({ to: '/m', label: 'Admin' })
  }

  return (
    <div className={`default-layout ${sidebarOpen === 1 ? 'open' : sidebarOpen === -1 ? 'close' : ''}`}>
      <div className="layout-sidebar">
        <h1 className="sidebar-logo">
          <i className="icon--emblem"><span>Nearcle</span></i>
          <span>ADMIN</span>
        </h1>

        <nav className="sidebar-nav">
          <ul>
            {menu.map(({ to, label, children }: { to: string, label: string, children?: Array<any> }, idx: number) => {
              return (
                <li key={`gnb-${idx}`}>
                  <NavLink to={to}>
                    {label}
                    <svg xmlns="http://www.w3.org/2000/svg"
                         width="12"
                         height="62"
                         viewBox="0 0 12 62">
                      <path d="M12,0c0,20-12,19-12,32s12,12,12,29"
                            fill="#222222"/>
                    </svg>
                  </NavLink>
                  {!!children &&
                    <ul className="nav-children">
                      {children.map(({to, label}: { to: string, label: string }, idxChild: number) => {
                        return (
                          <li key={`gnb-${idx}-${idxChild}`}>
                            <NavLink to={to}>
                              {label}
                              <svg xmlns="http://www.w3.org/2000/svg"
                                   width="12"
                                   height="62"
                                   viewBox="0 0 12 62">
                                <path d="M12,0c0,20-12,19-12,32s12,12,12,29"
                                      fill="#222222"/>
                              </svg>
                            </NavLink>
                          </li>
                        )
                      })}
                    </ul>
                  }
                </li>
              )
            })}
          </ul>
        </nav>

        <div className="sidebar-info">
          {user.nickname}
          <em>{user.userId}</em>
          <UiButton onClick={logout}>
            로그아웃
          </UiButton>
          <button className="toggle"
                  onClick={toggleSidebar}>
            ≡
          </button>
        </div>
      </div>

      <div className="layout-content">
        {!!title &&
          <h2 className="content-title">{title}</h2>
        }
        {children}
      </div>

      {loader.loading &&
        <div className="layout-loader">
          <div className="dim">
            <i className="icon--loader" />
          </div>
        </div>
      }

    </div>
  )
}

export default DefaultLayout
