import React from 'react'

import './UiInput.scss'

const UiInput = ({ type, label, value, checked, disabled, placeholder, description, error, onKeyUp, onChange }
  :{ type?: string,
     label?: string | JSX.Element,
     value?: string,
     checked?: boolean,
     disabled?: boolean,
     placeholder?: string,
     description?: string,
     error?: string,
     onKeyUp: Function,
     onChange: Function }): JSX.Element => {

  if (!type) {
    type = 'text'
  }

  return (
    <div className="ui-input">
      {!!label &&
        <h6 className="ui-input-label">
          {label}
        </h6>
      }
      {type === 'textarea' &&
        <textarea className="ui-input-element"
                  value={value}
                  placeholder={placeholder}
                  disabled={disabled}
                  onKeyUp={(e: any) => onKeyUp(e)}
                  onChange={(e: any) => onChange(e)}
                  rows={5} />
      }
      {type !== 'textarea' &&
        <input className="ui-input-element"
               type={type}
               value={value}
               checked={checked}
               placeholder={placeholder}
               disabled={disabled}
               onKeyUp={(e: any) => onKeyUp(e)}
               onChange={(e: any) => onChange(e)} />
      }
      {type === 'checkbox' &&
        <>
          {checked &&
            <>예</>
          }
          {!checked &&
            <>아니오</>
          }
        </>
      }
      {!error &&
        <p className="ui-input-description">
          {description}
        </p>
      }
      {!!error &&
        <p className="ui-input-description error">
          {error}
        </p>
      }
    </div>
  )
}

UiInput.defaultProps = {
  onKeyUp: () => {},
  onChange: () => {}
}

export default UiInput
