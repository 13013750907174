import React, { useState } from 'react'
import { useNavigate } from 'react-router-dom';

import { signIn } from 'api/auth'
import { useAuth } from 'context/Auth'

import UiInput from 'components/ui/UiInput'
import UiButton from 'components/ui/UiButton'

import './index.scss'

const SignIn = (): JSX.Element => {
  let auth = useAuth()
  const navigate = useNavigate()

  const [value, setValue] = useState({
    userId: '',
    password: ''
  })
  
  const [error, setError] = useState({
    userId: '',
    password: ''
  })

  const inputOnChange = (name: string, value: string): void => {
    setValue((prevState) => ({
      ...prevState,
      [name]: value
    }))

    setError((prevState) => ({
      ...prevState,
      [name]: null
    }))
  }

  const signInOnSubmit = (): void => {
    signIn(value).then(response => {
      auth.signIn(response.data.accessToken, response.data.user)
      navigate('/dashboard')
    }).catch(error => {
      alert(error.message)
    })
  }

  const isSignInDisabled = (): boolean => {
    const {
      userId,
      password
    } = value

    return userId.length < 1 || password.length < 1
  }
  
  return (
    <div className="sign-in">
      <div className="sign-in-wrap">
        <span className="graphic--hole" />
        <span className="graphic--strap" />

        <h1 className="sign-in-logo">
          <i className="icon--emblem"><span>Nearcle</span></i>
        </h1>

        <UiInput type="text"
                 label="Email"
                 placeholder=""
                 value={value.userId}
                 error={error.userId}
                 onKeyUp={(e: any) => { if (e.key === 'Enter') signInOnSubmit() } }
                 onChange={(e: any) => inputOnChange("userId", e.target.value)} />
        <UiInput type="password"
                 label="Password"
                 value={value.password}
                 error={error.password}
                 onKeyUp={(e: any) => { if (e.key === 'Enter') signInOnSubmit() } }
                 onChange={(e: any) => inputOnChange("password", e.target.value)} />

        <div className="sign-in-buttons">
          <UiButton full={true}
                    onClick={signInOnSubmit}
                    disabled={isSignInDisabled()}>
              로그인
          </UiButton>
        </div>
      </div>
    </div>
  )
}

export default SignIn
