import React from 'react'

import { archiveCategoryList, archiveCategoryInfo, archiveCategoryUpsert, archiveCategoryDelete } from 'api/archive'

import SearchList from 'components/template/SearchList'

const ArchiveCategoryList = (): JSX.Element => {
  return (
    <div className="archive-category-list">
      <SearchList listApi={archiveCategoryList}
                  infoApi={archiveCategoryInfo}
                  insertApi={archiveCategoryUpsert}
                  editApi={archiveCategoryUpsert}
                  deleteApi={archiveCategoryDelete} />
    </div>
  )
}

export default ArchiveCategoryList
