import React from 'react'

import './BlankLayout.scss'

const BlankLayout = ({ children}: { children: JSX.Element }): JSX.Element => {
  return (
    <div>
      {children}
    </div>
  )
}

export default BlankLayout
