import httpClient from 'utils/http'
import { getAuthToken, hashPassword } from 'utils/auth'

export function signIn (params: Object): Promise<any> {
  const url = '/auth/l'

  // params.password = hashPassword(params.password)

  return httpClient.post(url, params).then(response => {
    return response.data  
  })  
}

export function userInfo (): Promise<any> {
  const url = '/auth/i'
  const authToken = getAuthToken()

  if (authToken) {
    return httpClient.post(url).then(response => {
      return response.data
    })
  } else {
    return Promise.resolve(null)
  }
}
