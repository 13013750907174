import { format, differenceInMinutes, differenceInDays } from 'date-fns'

export const emailRegex = /^[0-9a-zA-Z]([-_.]?[0-9a-zA-Z])*@[0-9a-zA-Z]([-_.]?[0-9a-zA-Z])*\.[a-zA-Z]{2,3}$/i
export const alphabetCharRegex = /[a-zA-Z]/
export const numericCharRegex = /[0-9]/
export const specialCharRegex = /[~!@#$%^*()\-_=+|[\]{}:'",.<>/?]/

export function getData (object, key) {
  const value = key.split('.').reduce((a, b) => {
    if (a && typeof a === 'object') {
      return a[b]
    } else {
      return null
    }
  }, object)

  return value
}

export function getS3Url (pathKey, timestamp) {
  if (!pathKey) {
    return null
  }

  const [bucketName, filePath] = pathKey.split(':')
  let url = `https://s3.ap-northeast-2.amazonaws.com/${bucketName}/${filePath}`

  if (timestamp) {
    url += `?v=${timestamp}`
  }
  return url
}

export function timeDiff (scale, a, b = null) {
  const dateA = new Date(a)
  const dateB = b ? new Date(b) : new Date()

  if (dateB < dateA) {
    dateB.setDate(dateB.getDate() + 1)
  }

  let divide = 1000
  if (scale === 's') {
    divide *= 1
  } else if (scale === 'm') {
    divide *= 60
  } else if (scale === 'h') {
    divide *= 60 * 60
  } else if (scale === 'd') {
    divide *= 60 * 60 * 24
  }
  return Math.floor((dateB - dateA) / divide)
}

export function timeString (date) {
  const today = new Date()
  const dateAt = new Date(date)
  const minDiff = differenceInMinutes(today, dateAt)
  today.setHours(23)
  dateAt.setHours(0)
  const dayDiff = differenceInDays(today, dateAt)
  let dateString = format(dateAt, 'yyyy. MM. dd')
  if (dateAt.getFullYear() === today.getFullYear()) {
    if (dayDiff === 3) {
      dateString = '그끄제'
    } else if (dayDiff === 2) {
      dateString = '그제'
    } else if (dayDiff === 1) {
      dateString = '어제'
    } else if (dayDiff < 1) {
      if (minDiff < 1) {
        dateString = '방금'
      } else if (minDiff < 60) {
        dateString = `${minDiff}분`
      } else if (minDiff < 60 * 24) {
        dateString = `${Math.floor(minDiff / 60)}시간`
      }
    } else {
      dateString = format(dateAt, 'MM. dd')
    }
  }
  return dateString
}

export function isArray (value) {
  return Array.isArray(value)
}

export function isPlainObject (value) {
  return Object.prototype.toString.call(value) === '[object Object]'
}

export function isUserId (value) {
    const re = /^[a-zA-Z0-9!@#$%^*()\-_=+|[\]{}:'",.<>/?]{4,15}$/
    return re.test(value)
}

export function isPassword (value) {
  const lenCheck = value.length >= 6
  const alphabetNumber = alphabetCharRegex.test(value) && numericCharRegex.test(value)
  const alphabetSpecialChar = alphabetCharRegex.test(value) && specialCharRegex.test(value)
  const numberSpecialChar = numericCharRegex.test(value) && specialCharRegex.test(value)
  return lenCheck && (alphabetNumber || alphabetSpecialChar || numberSpecialChar)
  
}

export function isEmail (value) {
  return emailRegex.test(value)
}

export function objectToForm (obj) {
  const formData = new FormData()

  const putInForm = (value, key) => {
    if (value && value instanceof Object && !(value instanceof File)) {
      if (Array.isArray(value)) {
        if (value.length > 0) {
          value.forEach((v, idx) => {
            putInForm(v, key ? `${key}[${idx}]` : idx)
          })
        } else {
          formData.append(key, value)
        }
      } else {
        Object.entries(value).forEach(([k, v]) => {
          putInForm(v, key ? `${key}[${k}]` : k)
        })
      }
    } else {
      formData.append(key, value)
    }
  }
  putInForm(obj)
  return formData
}

export const image = (url, onLoad, onError, rejectTimeout = 5000) => new Promise((resolve, reject) => {
  let timer = null

  const img = new Image()

  img.addEventListener('load', () => {
    if (timer) {
      clearTimeout(timer)
    }
    if (onLoad) {
      onLoad(img)
    }
    resolve(img)
  })

  img.addEventListener('error', (event) => {
    if (timer) {
      clearTimeout(timer)
    }
    if (onError) {
      onError(img)
    }
    reject(`${event.type}: ${event.message}`)
  })

  img.src = url

  if (rejectTimeout) {
    timer = setTimeout(() => reject('Timeout exception'), rejectTimeout)
  }
})

export const addListener = (el, type, func) => {
  if (el) {
    el.addEventListener('scroll', func)
  }
}

export const removeListener = (el, type, func) => {
  if (el) {
    el.removeEventListener('scroll', func)
  }
}

export const br2nl = (content) => {
  return (content || '').replace(/<br>/g, '\n')
}

export const removeTags = (content) => {
  return (content || '').replace(/<\/?[^>]+(>|$)/g, '')
}

export const resizeImage = (file, dataUri, { resizeSize, imageType, imageQuality }, delay = 200) => {
  return new Promise((resolve, reject) => {
    try {
      setTimeout(() => {
        image(dataUri, (img) => {
          let resizeWidth = resizeSize.width
          let resizeHeight = resizeSize.height

          const width = img.width
          const height = img.height
          if (!resizeWidth && !resizeHeight) {
            resizeWidth = width
            resizeHeight = height
          } else if (!resizeWidth && resizeHeight) {
            resizeWidth = width * resizeHeight / height
          } else if (resizeWidth && !resizeHeight) {
            resizeHeight = height * resizeWidth / width
          }

          let ratio
          if (width > height) {
            ratio = resizeHeight / height
          } else {
            ratio = resizeWidth / width
          }

          let canvas = document.createElement('canvas')
          canvas.width = resizeWidth
          canvas.height = resizeHeight

          const dw = width * ratio
          const dh = height * ratio
          const dx = (resizeWidth - dw) / 2
          const dy = (resizeHeight - dh) / 2

          let ctx = canvas.getContext('2d')
          ctx.fillStyle = 'white'
          ctx.fillRect(0, 0, canvas.width, canvas.height)
          ctx.drawImage(img, dx, dy, dw, dh)

          const thumbnailUrl = canvas.toDataURL(imageType, imageQuality)
          const resizedFile = base64ToFile(thumbnailUrl, file)
          const originalFile = base64ToFile(dataUri, file)
          resolve({ dataUri, thumbnailUrl, resizedFile, originalFile, ratio: width / height })
        })
      }, delay)
    } catch (e) {
      reject(e)
    }
  })
}

export const base64ToFile = (dataURI, origFile) => {
  let byteString, mimeString
  if (dataURI.split(',')[0].indexOf('base64') !== -1) {
    byteString = atob(dataURI.split(',')[1])
  } else {
    byteString = decodeURI(dataURI.split(',')[1])
  }
  mimeString = dataURI.split(',')[0].split(':')[1].split('')[0]
  let content = []
  for (let i = 0; i < byteString.length; i++) {
    content[i] = byteString.charCodeAt(i)
  }
  let newFile = new File(
    [new Uint8Array(content)], origFile.name, { type: mimeString }
  )
  let origProps = [
    'upload', 'status', 'previewElement', 'previewTemplate', 'accepted'
  ]
  origProps.forEach(p => {
    newFile[p] = origFile[p]
  })

  return newFile
}
