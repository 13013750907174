import React from 'react'

import { roomKeywordList, roomKeywordInfo, roomKeywordUpsert, roomKeywordDelete } from 'api/talk'

import SearchList from 'components/template/SearchList'

const TalkRoomKeywordList = (): JSX.Element => {
  return (
    <div className="talk-list">
      <SearchList listApi={roomKeywordList}
                  infoApi={roomKeywordInfo}
                  insertApi={roomKeywordUpsert}
                  editApi={roomKeywordUpsert}
                  deleteApi={roomKeywordDelete} />
    </div>
  )
}

export default TalkRoomKeywordList
