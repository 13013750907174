import React from 'react'

import { interestList, interestInfo, interestUpsert, interestDelete } from 'api/user'

import SearchList from 'components/template/SearchList'

const InterestList = (): JSX.Element => {
  return (
    <div className="interest-list">
      <SearchList listApi={interestList}
                  infoApi={interestInfo}
                  insertApi={interestUpsert}
                  editApi={interestUpsert}
                  deleteApi={interestDelete} />
    </div>
  )  
}  

export default InterestList
