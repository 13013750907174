import React from 'react'

import { boardList, boardInfo, boardUpsert, boardDelete } from 'api/community'

import SearchList from 'components/template/SearchList'

const ComunityList = (): JSX.Element => {
  return (
    <div className="community-list">
      <SearchList listApi={boardList}
                  infoApi={boardInfo}
                  insertApi={boardUpsert}
                  editApi={boardUpsert}
                  deleteApi={boardDelete} />
    </div>
  )  
}  

export default ComunityList
