import bcrypt from 'bcryptjs'  
import { BCRYPT_SALT_ROUNDS } from 'config'

const ACCESS_TOKEN_NAME = 'adminAccessToken'  

export function getAuthToken (): string | null {
  return localStorage.getItem(ACCESS_TOKEN_NAME) || null  
}

export function removeAuthToken (): void {
  localStorage.removeItem(ACCESS_TOKEN_NAME)  
}

export function setAuthToken (token: string): void {
  if (token) {
    localStorage.setItem(ACCESS_TOKEN_NAME, token)  
  } else {
    removeAuthToken()  
  }
}

export function hashPassword (password: string): string {
  return bcrypt.hashSync(password, Number(BCRYPT_SALT_ROUNDS))  
}
