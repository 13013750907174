import httpClient from 'utils/http'

export function userList (params: Object): Promise<any> {
  const url = '/u/l'

  return httpClient.post(url, params).then(response => {
    return response.data
  })
}

export function userInfo (params: Object): Promise<any> {
  const url = '/u/i'

  return httpClient.post(url, params).then(response => {
    return response.data
  })
}

export function userUpsert (params: Object): Promise<any> {
  const url = '/u/u'

  return httpClient.post(url, params).then(response => {
    return response.data
  })
}

export function userDelete (params: Object): Promise<any> {
  const url = '/u/d'

  return httpClient.post(url, params).then(response => {
    return response.data
  })
}

export function interestList (params: Object): Promise<any> {
  const url = '/u/i/l'

  return httpClient.post(url, params).then(response => {
    return response.data
  })
}

export function interestInfo (params: Object): Promise<any> {
  const url = '/u/i/i'

  return httpClient.post(url, params).then(response => {
    return response.data
  })
}

export function interestUpsert (params: Object): Promise<any> {
  const url = '/u/i/u'

  return httpClient.post(url, params).then(response => {
    return response.data
  })
}

export function interestDelete (params: Object): Promise<any> {
  const url = '/u/i/d'

  return httpClient.post(url, params).then(response => {
    return response.data
  })
}

export function interestCategoryList (params: Object): Promise<any> {
  const url = '/u/i/c/l'

  return httpClient.post(url, params).then(response => {
    return response.data
  })
}

export function interestCategoryInfo (params: Object): Promise<any> {
  const url = '/u/i/c/i'

  return httpClient.post(url, params).then(response => {
    return response.data
  })
}

export function interestCategoryUpsert (params: Object): Promise<any> {
  const url = '/u/i/c/u'

  return httpClient.post(url, params).then(response => {
    return response.data
  })
}

export function interestCategoryDelete (params: Object): Promise<any> {
  const url = '/u/i/c/d'

  return httpClient.post(url, params).then(response => {
    return response.data
  })
}
